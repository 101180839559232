var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{attrs:{"items":_vm.items}}),(_vm.processing)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"65vh"}},[_c('Spinner')],1):_c('div',{staticStyle:{"over-flow":"hidden"}},[(_vm.datasets && _vm.datasets.length)?_c('vue-good-table',{attrs:{"columns":_vm.fields,"rows":_vm.datasets,"pagination-options":{
        enabled: true,
        perPage: 5,
      },"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('b-icon',{staticStyle:{"float":"left","font-weight":"bold","font-size":"1.3rem","margin-right":"20px","margin-top":"4px"},attrs:{"icon":"folder","variant":"info"}}),_c('a',{staticClass:"text-info nav-link p-0",on:{"click":function($event){return _vm.handleDatasetClick({type:props.row.project_type, id:props.row.id})}}},[_vm._v(_vm._s(props.row.name))])],1):_vm._e(),(props.column.field == 'progress')?_c('span',{staticClass:"row p-0",staticStyle:{"min-width":"200px"}},[_c('p',{staticClass:"col-3 pb-0 m-0 text-info"},[_vm._v(" "+_vm._s(Math.round(props.row.progress) + "%")+" ")]),_c('div',{staticClass:"col-8 p-1 pt-2"},[_c('b-progress',{attrs:{"value":props.row.progress,"variant":"info","height":"0.5em"}})],1)]):_vm._e()]}}],null,false,3861455468)}):_c('h4',{staticClass:"mt-5 text-danger"},[_vm._v(" You are currently not assigned any datasets, check again later! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"custom-navigation p-0 pt-1",attrs:{"toggleable":"lg","type":"light","variant":""}},[_c('div',{staticClass:"container"},[_c('b-navbar-brand',{staticClass:"py-0 my-0"},[_c('a',{attrs:{"href":"https://marconilab.org"}},[_c('img',{staticClass:"brand-image",attrs:{"src":require("../assets/marconi_lab.png"),"alt":""}})])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('router-link',{class:[
            'router-link',
            _vm.currentRoute.includes('login') || _vm.currentRoute.includes('signup')
              ? 'not-active'
              : 'active-link' ],attrs:{"to":"/"}},[_vm._v(" home")]),_c('router-link',{class:[
            'router-link',
            _vm.currentRoute.includes('admin') ? 'active-link' : 'not-active' ],attrs:{"to":"/administrator"}},[_vm._v(" admin")]),_c('router-link',{class:[
            'router-link',
            _vm.currentRoute.includes('login') ? 'active-link' : 'not-active' ],attrs:{"to":"/login"}},[_vm._v(" signin")]),_c('router-link',{class:[
            'router-link',
            _vm.currentRoute.includes('signup') ? 'active-link' : 'not-active' ],attrs:{"to":"/signup"}},[_vm._v(" signup")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
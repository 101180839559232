var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":6,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUI: false,
    }}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"clickable":true,"draggable":true,"position":m.position,"label":m.name},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
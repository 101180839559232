var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),(_vm.processing)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"65vh"}},[_c('Spinner')],1):_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.fields,"rows":_vm.currentDataset,"pagination-options":{
        enabled: true,
        perPage: 8,
      },"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"sort-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('b-icon',{staticStyle:{"float":"left","font-weight":"bold","font-size":"1.3rem","margin-right":"20px","margin-top":"4px"},attrs:{"icon":"folder","variant":"info"}}),_c('router-link',{staticClass:"text-info",attrs:{"to":("/user/datasets/" + (_vm.items[1].text) + "/" + (props.row.id))}},[_vm._v(_vm._s(props.row.name))])],1):_vm._e(),(props.column.field == 'labelled')?_c('span',[(props.row.labelled)?_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"info","font-scale":"1em"}}):_c('b-icon',{attrs:{"icon":"X-circle","variant":"danger","font-scale":"1em"}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }